/** Override these properties with localized versions */
export const LocalText = {
    Columns: "Columns",
    Filter: "Filter",
    ClearFilters: "Clear filters",
    AllDataFilteredOut: "No items match the current filters",
    All: "All",
    Minimum: "Minimum",
    Maximum: "Maximum",
    None: "None",
    AnyDate: "Any date",
    Today: "Today",
    From: "From",
    To: "To",
    MultiSelect: "Multi-select",
    HelpLink: "More info",
    ServerConnectionError: "Could not connect to server.",
    UnknownError: "An unknown error occurred.",
    RequestEntityTooLarge: "The request has exceeded the maximum upload size.",
    RequestEntityTooLargeDetails: (fileName: string, maxFileSize: string) => `File "${fileName}" is larger than the current file upload limit of ${maxFileSize}.`,
    UnsupportedMediaType: "Unsupported file type detected.",
};
